export const ProjectConfigOverride = {
  api_url: "https://api.pennicart.io",
  version: "2.1.2",

  /*============================================
  SQUARE
  =============================================*/
  SQUARE_CLIENT_ID: "sq0idp-Lg8l2z85aGBaaQOudQa7Zg",
  SQUARE_BASE_URL: "https://connect.squareup.com",
  SQUARE_REDIRECT_URI: "https://api.pennicart.io/square/auth",
};